.mapboxgl-ctrl-group{
    display: flex;
    flex-direction: column-reverse;
}

.mapboxgl-ctrl-group button{
    border-top: none;
}
.mapboxgl-ctrl-group button + button {
    border-bottom: 1px solid #ddd;
}

.mapboxgl-ctrl-group button:last-child{
    border-radius: 0;
    margin-bottom: -1px;
}
